import { message } from 'antd';
import Axios from 'axios';
import dayjs from 'dayjs';
import { batch } from 'react-redux';

import { CartActions } from './cart';
import { ClientActions } from './client';
import { CompanyActions } from './company';
import { HistoriesAction } from './history';
import { TIntervalActions, Types as TypesInterval } from './intervals';
import { PaymentActions, TPaymentActions, Types as TypesPayment } from './payment';
import { t } from '../../aqua-delivery-web-client-ui/i18n';
import { TFormOrder } from '../../routes/cart/hooks/useOrder';
import { translateMap } from '../../translations';
import { NavigateRoutes } from '../../utils/enums/NavigateRoutes';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { OrderPaymentSources } from '../../utils/enums/OrderPaymentSources';
import { PaymentCodes } from '../../utils/enums/PaymentCodes';
import { StorageKeys } from '../../utils/enums/StorageKeys';
import JSONHelper from '../../utils/JSONHelper';
import {
    CheckoutFields,
    OrderNetworkItems,
    PromocodeStatus,
    TPromocodeStatus,
} from '../reducers/order';
import { TAction } from '../store';

export const Types = {
    ...TypesInterval,
    ...TypesPayment,
    SET_READY_FIELD: 'ORDER@SET:READY:FIELD',
    SET_DEFAULT_FIELD: 'ORDER@SET:DEFAULT:FIELD',
    SET_NETWORK_STATUS: 'ORDER@SET:NETWORK:STATUS',
    SET_PROMOCODE_STATUS: 'ORDER@SET:PROMOCODE:STATUS',
    SET_PROMOCODE_ERROR: 'ORDER@SET:PROMOCODE:ERROR',
    SET_ORDER_MODAL: 'ORDER@SET:ORDER:MODAL',
    SET_CANCEL_ORDER_REASONS: 'ORDER@SET:CANCEL:ORDER:REASONS',
    SET_ORDER_ERROR_MESSAGE: 'ORDER@SET:ORDER:ERROR:MESSAGE',
    SET_COMPLETE_NETWORK_STATUS: 'ORDER@SET:COMPLETE:NETWORK:STATUS',
};

export type TSetReadyField = {
    type: typeof Types.SET_READY_FIELD;
    payload: Record<CheckoutFields, boolean>;
};

export type TSetDefaultField = {
    type: typeof Types.SET_DEFAULT_FIELD;
    payload: Record<CheckoutFields, any>;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: Record<CheckoutFields, boolean>;
};

export type TSetPromocodeStatus = {
    type: typeof Types.SET_PROMOCODE_STATUS;
    payload: TPromocodeStatus;
};

export type TSetPromocodeError = {
    type: typeof Types.SET_PROMOCODE_ERROR;
    payload: string | null;
};

export type TSetCancelOrderReasons = {
    type: typeof Types.SET_CANCEL_ORDER_REASONS;
    payload: TCancelOrderReasons[];
};

export type TSetOrderErrorMessage = {
    type: typeof Types.SET_ORDER_ERROR_MESSAGE;
    payload: string;
};

export type OrderModal = {
    show: boolean;
    isSuccess: boolean;
    orderId: number | null;
    shippingDate: string | null;
    shippingDateTo: string | null;
};

export type TSetOrderModal = {
    type: typeof Types.SET_ORDER_MODAL;
    payload: OrderModal | null;
};

export type TSetCompleteNetworkStatus = {
    type: typeof Types.SET_COMPLETE_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TOrderActions =
    | TIntervalActions
    | TPaymentActions
    | TSetReadyField
    | TSetPromocodeStatus;

export type TTokenResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    basicLegalPerson: string;
    basicLegalPersonId: string;
    familyName: null | string;
    firstName: string;
    id: number;
    middleName: null | string;
    partnerId: string;
    phone: string;
    token: string;
    username: string;
};

export type TOrderResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    id: string;
    createdAt: string;
    order: string;
    orderDetails: {
        '@type': string;
        '@id': string;
        id: number;
        source: string;
        status: string;
        paymentType: string;
        orderType: string;
        deliveryCost: number;
        coinsAmount: number;
        createdAt: string;
        updatedAt: string;
        shippingDate: string;
        shippingDateTo: string;
        items: {
            '@type': string;
            '@id': string;
            product: {
                '@type': string;
                '@id': string;
                uuid: string;
                id: number;
                name: string;
                description: string;
                image: string;
            };
            quantity: number;
            price: number;
        }[];
        total: number;
        currency: string;
        address: {
            '@type': string;
            '@id': string;
            identity: {
                '@type': string;
                '@id': string;
                serviceUuid: string;
                monolithUuid: string;
                monolithId: number;
            };
            city: string;
            street: string;
            house: string;
            coordinates: {
                '@type': string;
                '@id': string;
                latitude: number;
                longitude: number;
            };
            name: string;
            cityId: number;
            districtId: number;
        };
        client: {
            '@type': string;
            '@id': string;
            phoneNumber: string;
            name: string;
            status: string;
            type: string;
            contractNumber: number;
            createdAt: string;
            updatedAt: string;
            externalId: string;
            email: string;
            partnerId: string;
            uuid: string;
        };
        payment: {
            '@type': string;
            '@id': string;
            partnerId: string;
            documentId: string;
            currency: string;
            amount: number;
            amountMoney: {
                amount: string;
                currency: string;
            };
            date: string;
            clientId: string;
            status: string;
            orderNumber: number;
        };
        timeZone: string;
        crmUrl: string;
        returnableContainerQuantity: number;
        legalPersonId: string;
        paymentMethodId: string;
        context: {
            routeUrl: string;
            items: {
                quantity: number;
                price: number;
                text: string;
            }[];
            discount: null;
        };
        partnerId: string;
        uuid: string;
    };
};

export type TPayHistoryOrder = {
    orderDetails: {
        id: number;
        payment: {
            orderNumber: number;
            clientId: string;
            amount: number;
        };
        address: {
            name: string;
        };
        client: {
            uuid: string;
        };
        uuid: string;
        total: number;
        context: {};
        paymentMethodId: string;
        currency: string;
    };
};

export type TAssignPromocodeResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    errorMessage: string;
    id: string;
    promotionCode: string;
    valid: boolean;
};

export type TCancelOrderReasons = {
    id: number;
    sequence: number;
    name: string;
    visible: number;
};

type OrderActionsType = {
    assignNote: (value: string) => TAction<Promise<void>>;
    assignPromocode: (promocode: string) => TAction<Promise<void>>;
    setPromocodeStatus: (status: TPromocodeStatus) => TSetPromocodeStatus;
    setPromocodeError: (error: string | null) => TSetPromocodeError;
    completeOrder: (data: TFormOrder) => TAction<Promise<void>>;
    sendOnlinePayment: (
        data: TOrderResponse | TPayHistoryOrder,
        source: OrderPaymentSources,
    ) => TAction<Promise<void>>;
    cancelOrder: (
        id: string,
        reasonId: number | null,
        note: string | null,
    ) => TAction<Promise<void>>;
    fetchCancelOrderReasons: () => TAction<Promise<void>>;
    setReadyField: (fieldName: CheckoutFields, value: boolean) => TAction<Promise<void>>;
    setDefaultField: (fieldName: CheckoutFields, value: any) => TAction<Promise<void>>;
    setCancelOrderReasons: (reasons: TCancelOrderReasons[]) => TSetCancelOrderReasons;
    setOrderModal: (data: OrderModal | null) => TSetOrderModal;
    setOrderErrorMessage: (error: string) => TSetOrderErrorMessage;
    setNetworkStatus: (
        networkItem: OrderNetworkItems,
        status: NetworkStatus,
    ) => TAction<Promise<void>>;
    clearOrder: () => TAction<Promise<void>>;
    setCompleteNetworkStatus: (status: NetworkStatus) => TSetCompleteNetworkStatus;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

const tMap = translateMap.errors;

export const OrderActions: OrderActionsType = {
    assignNote(value) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    note: value ? value : null,
                };

                httpClientServices
                    .getClient('cart')
                    .post(services.cart.assignNote, data)
                    .then(() => {
                        dispatch(this.setReadyField(CheckoutFields.note, true));
                    })
                    .catch(() => {
                        dispatch(this.setReadyField(CheckoutFields.note, false));
                    });
            }
        };
    },
    assignPromocode(value) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    promotionCode: value,
                };
                dispatch(this.setNetworkStatus('promocode', NetworkStatus.loading));

                return httpClientServices
                    .getClient('cart')
                    .post<TAssignPromocodeResponse>(services.cart.assignPromocode, data)
                    .then(({ data }) => {
                        batch(() => {
                            if (value !== '') {
                                const status = data.valid
                                    ? PromocodeStatus.Valid
                                    : PromocodeStatus.Invalid;
                                dispatch(this.setPromocodeStatus(status));
                                if (status === PromocodeStatus.Invalid) {
                                    dispatch(this.setPromocodeError(data.errorMessage));
                                }
                            } else {
                                dispatch(this.setPromocodeStatus(null));
                                dispatch(this.setPromocodeError(null));
                            }
                        });
                    })
                    .catch(e => errorHandler(e, t(tMap.assignPromocode)))
                    .finally(() => {
                        dispatch(this.setNetworkStatus('promocode', NetworkStatus.ready));
                    });
            }
        };
    },
    setPromocodeStatus(status) {
        return {
            type: Types.SET_PROMOCODE_STATUS,
            payload: status,
        };
    },
    setPromocodeError(error) {
        return {
            type: Types.SET_PROMOCODE_ERROR,
            payload: error,
        };
    },
    sendOnlinePayment(order, source = OrderPaymentSources.History) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().company;
            const { data: userData } = getState().user;
            if (userData && info) {
                const returnUrl = `${window.location.origin}${
                    source === OrderPaymentSources.History
                        ? `${NavigateRoutes.Orders}/`
                        : '/?success=true&order_id='
                }${order.orderDetails.id}`;
                dispatch(this.setCompleteNetworkStatus(NetworkStatus.loading));
                await dispatch(ClientActions.clientEnrichToken()).then(token => {
                    const requestData = {
                        documentId: order.orderDetails.uuid,
                        orderNumber: order.orderDetails.payment.orderNumber,
                        clientId:
                            order.orderDetails.payment.clientId || order.orderDetails.client.uuid,
                        clientEmail: userData.email,
                        amount: order.orderDetails.payment.amount,
                        context: order.orderDetails.context,
                        returnUrl,
                        clientPhone: userData.phone_number,
                        paymentMethod: order.orderDetails.paymentMethodId,
                        clientAddress: order.orderDetails.address.name,
                        currency: order.orderDetails.currency,
                        autoRedirect: false,
                    };
                    httpClientServices
                        .getClient('payment')
                        .post(services.payment.paymentRequest, requestData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'device-info': JSON.stringify({
                                    uniqueDeviceId: localStorage.getItem(StorageKeys.deviceId),
                                }),
                            },
                        })
                        .then(({ data }) => {
                            dispatch(CartActions.clearCart());
                            if (data.paymentUrl) {
                                dispatch(PaymentActions.setPaymentUrl(data.paymentUrl));
                            } else {
                                dispatch(
                                    this.setOrderModal({
                                        show: true,
                                        isSuccess: false,
                                        orderId: data.orderDetails.id,
                                        shippingDate: dayjs(data.orderDetails.shippingDate)
                                            .tz(data.orderDetails.timeZone)
                                            .format('YYYY-MM-DD HH:mm:ss'),
                                        shippingDateTo: dayjs(data.orderDetails.shippingDateTo)
                                            .tz(data.orderDetails.timeZone)
                                            .format('YYYY-MM-DD HH:mm:ss'),
                                    }),
                                );
                            }
                        })
                        .catch(e => {
                            message.error(e.response?.data['hydra:description']);
                        })
                        .finally(() => {
                            dispatch(this.setCompleteNetworkStatus(NetworkStatus.ready));
                        });
                });
            }
        };
    },
    completeOrder(formData) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const onlineCodes = [PaymentCodes.sbp, PaymentCodes.online];
            const isReadyFields = getState().order.isReadyFields;
            const { info } = getState().cart;
            const cart = info?.cart;
            const canComplete = Object.values(isReadyFields).every(field => field === true);
            if (canComplete && cart) {
                const data = {
                    cart,
                };
                dispatch(this.setCompleteNetworkStatus(NetworkStatus.loading));

                return httpClientServices
                    .getClient('cart')
                    .post<TOrderResponse>(services.cart.complete, data, {
                        headers: {
                            'x-device-info': JSON.stringify({
                                nativeVersion: `site_2_${process.env.REACT_APP_VERSION}`,
                            }),
                        },
                    })
                    .then(({ data }) => {
                        const { payments } = getState().order;

                        batch(() => {
                            dispatch(CartActions.clearCart());
                            dispatch(this.clearOrder());
                            dispatch(CompanyActions.fetchCompanyInfo());
                        });

                        const payment = payments.find(
                            payment => formData.payment === payment['@id'],
                        );
                        if (payment?.setup.code && onlineCodes.includes(payment.setup.code)) {
                            return dispatch(
                                this.sendOnlinePayment(data, OrderPaymentSources.CreateOrder),
                            );
                        }

                        dispatch(
                            this.setOrderModal({
                                show: true,
                                isSuccess: true,
                                orderId: data.orderDetails.id,
                                shippingDate: dayjs(data.orderDetails.shippingDate)
                                    .tz(data.orderDetails.timeZone)
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                shippingDateTo: dayjs(data.orderDetails.shippingDateTo)
                                    .tz(data.orderDetails.timeZone)
                                    .format('YYYY-MM-DD HH:mm:ss'),
                            }),
                        );
                    })
                    .catch(e => {
                        const errorStr = e.response.data['hydra:description'];

                        if (JSONHelper.isJsonInString(errorStr)) {
                            const json = JSONHelper.getJsonFromString(errorStr);

                            if (json) {
                                const firstKey = Object.keys(json)[0];

                                dispatch(this.setOrderErrorMessage(json[firstKey]));
                                throw Error(e);
                            }
                        }

                        dispatch(this.setOrderErrorMessage(errorStr));
                        throw Error(e); // catch it in CreateOrderForm.tsx
                    })
                    .finally(() => {
                        dispatch(this.setCompleteNetworkStatus(NetworkStatus.ready));
                    });
            }
        };
    },
    clearOrder() {
        return async (dispatch, getState) => {
            const { defaultFields, isReadyFields } = getState().order;

            batch(() => {
                Object.keys(isReadyFields).forEach(key => {
                    if ((key as CheckoutFields) !== CheckoutFields.note) {
                        dispatch(this.setReadyField(key as CheckoutFields, false));
                    }
                });
                Object.keys(defaultFields).forEach(key => {
                    dispatch(this.setDefaultField(key as CheckoutFields, null));
                });
            });
        };
    },
    cancelOrder(id, reasonId, note) {
        return async (dispatch, _, { httpClientServices, services }) => {
            const externalOrder = `/order/external_orders/${id}`;
            let data;
            if (reasonId) {
                data = {
                    externalOrder,
                    reasonId,
                };
            } else {
                data = {
                    externalOrder,
                    note,
                };
            }

            httpClientServices
                .getClient('cart')
                .post<TOrderResponse>(services.cart.clientCancelOrder, data)
                .then(() => dispatch(HistoriesAction.clearHistories()))
                .then(() => dispatch(HistoriesAction.fetchHistories()))
                .catch(e => errorHandler(e, 'error'));
        };
    },
    fetchCancelOrderReasons() {
        return async (dispatch, _, { api, httpClientServices }) => {
            httpClientServices
                .getClient()
                .get(api.cancelReason)
                .then(({ data }) => dispatch(this.setCancelOrderReasons(data.data)))
                .catch(e => errorHandler(e, 'error'));
        };
    },
    setNetworkStatus(networkItem, status) {
        return async (dispatch, getState) => {
            const networkItems = getState().order.networkItems;
            dispatch({
                type: Types.SET_NETWORK_STATUS,
                payload: { ...networkItems, [networkItem]: status },
            });
        };
    },
    setCancelOrderReasons(reasons) {
        return {
            type: Types.SET_CANCEL_ORDER_REASONS,
            payload: reasons,
        };
    },
    setReadyField(fieldName, value) {
        return async (dispatch, getState) => {
            const isReadyFields = getState().order.isReadyFields;
            dispatch({
                type: Types.SET_READY_FIELD,
                payload: { ...isReadyFields, [fieldName]: value },
            });
        };
    },
    setOrderModal(data) {
        return {
            type: Types.SET_ORDER_MODAL,
            payload: data,
        };
    },
    setDefaultField(fieldName, value) {
        return async (dispatch, getState) => {
            const defaultFields = getState().order.defaultFields;
            dispatch({
                type: Types.SET_DEFAULT_FIELD,
                payload: { ...defaultFields, [fieldName]: value },
            });
        };
    },
    setOrderErrorMessage(error) {
        return {
            type: Types.SET_ORDER_ERROR_MESSAGE,
            payload: error,
        };
    },
    setCompleteNetworkStatus(status) {
        return {
            type: Types.SET_COMPLETE_NETWORK_STATUS,
            payload: status,
        };
    },
};
